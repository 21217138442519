/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-jsdoc */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import fetcher from '../SharedFetcher'
import { getDataUrlFromUrl, getSupportedToken, paginatingUrl } from '../misc'
import { ALL_LEAGUES, ALL_NETWORK, ALL_ODD, ALL_PREDICTION_CATEGORY, PRIVATE_FILE } from '../config/RestEndpoints'
import { Button } from 'react-bootstrap'
import { LeagueContext } from '../../components/context/leagueContext'
import ILeague from '../../interface/ILeague'
import { toast } from 'react-toastify'
import IResStruct from '../../interface/IResStruct'
import IOdd from '../../interface/IOdd'
import IOddGroup from '../../interface/IOddGroup'
import IPredictionCategory from '../../interface/IPredictionCategory'
import { IAny } from '../../interface/IAny'

export function useTokenAndNetwork(optionsOnly = true, type = 'deposit') {
  const [supportedTokens, setSupportedTokens] = useState<any[]>([])
  const [tokens, setTokens] = useState<any[]>([])
  const [tokenOptions, setTokenOptions] = useState<any[]>([])
  const [networks, setNetworks] = useState<any[]>([])
  const [networkOptions, setNetworkOptions] = useState<any[]>([])

  async function onNetworkSelection(networkId: string) {
    setTokens(supportedTokens?.filter((token) => token?.network === networkId) || [])
  }

  useEffect(() => {
    fetcher.fetch<IResStruct>(ALL_NETWORK).then((data) => {
      setNetworks(data?.data?.networks?.results || [])
    })

    getSupportedToken(type).then((tks) => setSupportedTokens(tks || []))
  }, [])

  useEffect(
    () =>
      setTokenOptions(
        tokens?.map((token) => (
          <option key={token?._id} value={token?._id} title={token?.name}>
            {token?.symbol}
          </option>
        )) || [],
      ),
    [tokens],
  )

  useEffect(
    () =>
      setNetworkOptions(
        networks?.map((network) => (
          <option key={network?._id} value={network?._id}>
            {network?.name}
          </option>
        )) || [],
      ),
    [networks],
  )

  if (!optionsOnly) {
    return [
      tokens,
      setTokens,
      tokenOptions,
      setTokenOptions,
      networks,
      setNetworks,
      networkOptions,
      setNetworkOptions,
      onNetworkSelection,
    ]
  } else {
    return [tokenOptions, networkOptions, onNetworkSelection]
  }
}

export function useHistoryButton(showing = false, style: IAny = {}, className = '') {
  const [history, setHistory] = useState(showing)

  return [
    history,
    setHistory,
    <small key="historyButton" style={{ float: style.float ? style.float : 'right' }}>
      <Button className={className} style={style} onClick={() => setHistory(!history)}>
        <i className={`fas fa-${!history ? 'history' : 'arrow-left'}`}></i>
      </Button>
    </small>,
  ]
}

export function useLeagues(): [
  ILeague[],
  string,
  (previousActiveLeague: string | number, currentActiveLeague?: any) => any,
  previousLink?: string,
  nextLink?: string,
  setUrl?: any,
] {
  const [leagues, setLeagues] = useState<ILeague[]>([])
  const [previousLink, setPreviousLink] = useState<string>('')
  const [nextLink, setNextLink] = useState<string>('')
  const [url, setUrl] = useState<string>(ALL_LEAGUES)

  const { activeLeague, setActiveLeague } = useContext(LeagueContext)

  useEffect(() => {
    fetcher.fetch<IResStruct>(url).then((data) => {
      if (data?.data?.leagues?.results) {
        setLeagues(data?.data?.leagues?.results || [])
        if (data?.data?.leagues?.results[0]) {
          const firstLeague = data?.data?.leagues?.results[0]._id
          setActiveLeague(firstLeague || '')
          setPreviousLink(data?.data?.leagues?.metadata?.previousUrl ? data?.data?.leagues?.metadata?.previousUrl : '')
          setNextLink(data?.data?.leagues?.metadata?.nextUrl ? data?.data?.leagues?.metadata?.nextUrl : '')
        }
      }
    })
  }, [url])

  return [leagues, activeLeague, setActiveLeague, previousLink, nextLink, setUrl]
}

export function useOddGrouping(matchId: string): {
  otherGroupsToSelectKeys: string[]
  otherSelectedCategory: string
  setOtherSelectedCategory: (categoryId: string) => void
  displayingPartitionOddGroups: IOddGroup
} {
  const [odds, setOdds] = useState<IOdd[]>([])
  const [predictionCategories, setPredictionCategories] = useState<IPredictionCategory[]>([])
  const [otherSelectedCategory, setOtherSelectedCategory] = useState<string>('')

  const oddGroups = useMemo<IOddGroup>(() => {
    if (!odds || !predictionCategories) {
      return {}
    }
    if (!(odds.length > 0 && predictionCategories.length > 0)) {
      return {}
    }
    const groups: IOddGroup = {}

    for (const predictionCategory of predictionCategories) {
      groups[predictionCategory.groupName] = {}
    }

    for (const odd of odds) {
      const predictionCategoryById = predictionCategories.find(
        (predictionCategory) => predictionCategory._id == `${odd.preId.predictionCategory}`,
      )
      if (!predictionCategoryById) {
        continue
      }
      let oddGroupingCategory = groups[predictionCategoryById.groupName]
      if (!oddGroupingCategory) {
        oddGroupingCategory = groups[predictionCategoryById.groupName] = {}
      }

      /**
       * oddGroupingCategory = {
       *  3way: {
       *    win1: odd
       *    winx: odd
       *    win2: odd
       *  }
       * }
       */

      oddGroupingCategory[odd.preId.predictionName] = odd
    }

    return groups
  }, [odds, predictionCategories])

  const displayingPartitionOddGroups = useMemo<IOddGroup>(() => {
    if (!oddGroups || Object.keys(oddGroups).length < 1) {
      return {}
    }

    const groups: IOddGroup = {}

    const oddGroupKeys = Object.keys(oddGroups)

    const hasOtherSelectedCategory = otherSelectedCategory && oddGroupKeys.includes(otherSelectedCategory)

    oddGroupKeys
      .slice(0, !hasOtherSelectedCategory ? 3 : 2)
      .forEach((oddCategory) => (groups[oddCategory] = oddGroups[oddCategory]))

    if (hasOtherSelectedCategory) {
      groups[otherSelectedCategory] = oddGroups[otherSelectedCategory]
    }

    return groups
  }, [otherSelectedCategory, oddGroups])

  const otherGroupsToSelectKeys = useMemo<string[]>(() => {
    if (
      !oddGroups ||
      Object.keys(oddGroups).length < 1 ||
      !displayingPartitionOddGroups ||
      Object.keys(displayingPartitionOddGroups).length < 1
    ) {
      return []
    }

    const groups: string[] = []

    const displayingPartitionOddGroupKeys = Object.keys(displayingPartitionOddGroups)
    const oddGroupKeys = Object.keys(oddGroups)

    for (const oddGroupKey of oddGroupKeys) {
      const hasIncludedOddGroup = displayingPartitionOddGroupKeys.includes(oddGroupKey)
      if (!hasIncludedOddGroup) {
        groups.push(oddGroupKey)
      }
    }

    return groups
  }, [displayingPartitionOddGroups, oddGroups])

  useEffect(() => {
    const data = {
      populate: ['matchId', 'preId'],
      matchId: matchId,
    }
    fetcher
      .fetch<IResStruct>(paginatingUrl(ALL_ODD, data, 1000))
      .then((data) => {
        if (data) {
          if (!data?.data?.status) {
            toast.error(data?.data?.message)
          }
          setOdds(data.data['odds']?.results || [])
        }
      })
      .catch(() => {})

    fetcher
      .fetch<IResStruct>(ALL_PREDICTION_CATEGORY)
      .then((data) => {
        if (data) {
          if (!data?.data?.status) {
            toast.error(data?.data?.message)
          }
          setPredictionCategories(data.data['predictionCategories']?.results || [])
        }
      })
      .catch(() => {})
  }, [])

  return {
    otherGroupsToSelectKeys,
    otherSelectedCategory,
    setOtherSelectedCategory,
    displayingPartitionOddGroups,
  }
}

export function useGetDataUri(initFilename = '') {
  const [filename, setFilename] = useState(initFilename)
  const [dataUri, setDataUri] = useState('')

  useEffect(() => {
    async function getData() {
      const auri = await getDataUrlFromUrl(PRIVATE_FILE + filename)
      setDataUri(auri as any)
    }
    if (filename && filename !== '') {
      getData()
    }
  }, [filename])

  return [dataUri || '../assets/img/avatars/1.png', setFilename]
}

/* export function useTokenAndNetwork(optionsOnly = true, type = "deposit") {
  let [tokens, setTokens] = useState([]);
  let [tokenOptions, setTokenOptions] = useState([]);
  let [networks, setNetworks] = useState([]);
  let [networkOptions, setNetworkOptions] = useState([]);

  async function onTokenSelection(token) {
    let data = await fetcher.fetch(TOKEN_NETWORK + token.id);
    if (data) {
      if (data.connection.statusCode !== 200) {
        toast.error(data.data.message);
      } else setNetworks([data.data.network]);
    } else {
      toast.error(`Error fetching network for ${token.name}.`);
    }
  }

  useEffect(() => {
    const fn = async () => {
      let supportedToken = await getSupportedToken(type)
      if (supportedToken[0]) {
        const { _id, name } = supportedToken[0];
        onTokenSelection({ id: _id, name });
      }
      setTokens(supportedToken);
    };
    fn();
  }, []);

  useEffect(() => {
    if (tokens.length > 0) {
      let options = [];
      for (const { _id, name, symbol } of tokens) {
        options.push(
          <option key={_id} value={_id} title={name}>
            {symbol}
          </option>
        );
      }
      setTokenOptions(options);
    }
  }, [tokens]);

  useEffect(() => {
    if (networks.length > 0) {
      let options = [];
      for (const { _id, name } of networks) {
        options.push(
          <option key={_id} value={_id}>
            {name}
          </option>
        );
      }
      setNetworkOptions(options);
    }
  }, [networks]);

  if (!optionsOnly) {
    return [
      tokens,
      setTokens,
      tokenOptions,
      setTokenOptions,
      networks,
      setNetworks,
      networkOptions,
      setNetworkOptions,
      onTokenSelection,
    ];
  } else {
    return [tokenOptions, networkOptions, onTokenSelection];
  }
} */
