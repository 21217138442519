/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

function ThemeToggler({ refresh }: any) {
  const scrollUp = () => (document.documentElement.scrollTop = 0)

  return (
    <>
      <div className="list-group" style={{ position: 'fixed', right: '20px', bottom: '65px' }}>
        <span title="Refresh" className="list-group-item" style={{ color: 'red', cursor: 'pointer' }} onClick={refresh}>
          <i className="font-lg bx bx-rotate-right bx-sm align-middle" />
        </span>
        <span
          title="Scroll to top"
          className="list-group-item"
          style={{ color: 'red', cursor: 'pointer' }}
          onClick={scrollUp}
        >
          <i className="font-lg bx bx-chevron-up-circle bx-sm align-middle" />
        </span>
      </div>
    </>
  )
}

export default ThemeToggler
