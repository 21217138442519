import React from 'react'

function Loader() {
  return (
    <>
      <div className="loader"></div>
    </>
  )
}

export default Loader
