/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useReducer } from 'react'
import { OddsProvider } from './oddsContext'

function reducer(previousActiveLeague: number | string, currentActiveLeague?: any) {
  return currentActiveLeague || previousActiveLeague || ''
}

const LeagueContext = createContext({
  activeLeague: 'activeLeague',
  setActiveLeague: reducer,
})

const LeagueProvider = ({ children }: any) => {
  const [activeLeague, setActiveLeague] = useReducer(reducer, '')

  return (
    <LeagueContext.Provider value={{ activeLeague, setActiveLeague }}>
      <OddsProvider>{children}</OddsProvider>
    </LeagueContext.Provider>
  )
}

export { LeagueContext, LeagueProvider }
