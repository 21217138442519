/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useReducer, useRef, useState } from 'react'
import Main from '../layout/Main'
import { LeagueContext } from '../components/context/leagueContext'
import MatchView from '../components/tabs/match/match_tab_components/MatchView'
import Login from './Login'
import SignUp from './SignUp'
import ForgetPassword from './ForgetPassword'
import ModalBox from '../components/general/Modal'

export enum OpenType {
  LOGIN = 'login',
  SIGN_UP = 'signup',
  FORGOT_PASSWORD = 'forgotpassword',
}

function Dashboard(props: any) {
  const { activeLeague } = useContext(LeagueContext)
  const [openingType, setOpeningType] = useState(props?.open)
  const openType = props?.open

  let Type = Login
  switch (openType) {
    case OpenType.SIGN_UP:
      Type = SignUp
      break
    case OpenType.FORGOT_PASSWORD:
      Type = ForgetPassword
      break
  }

  const [noLiveMatchWhen, setNoLiveMatchWhen] = useReducer(
    (_previousNoLiveMatchWhen: any, currentNoLiveMatchWhen: Date | string) => {
      if (currentNoLiveMatchWhen instanceof Date) {
        return `(0?${currentNoLiveMatchWhen.getDate()})-${
          currentNoLiveMatchWhen.getMonth() + 1
        }-${currentNoLiveMatchWhen.getFullYear()}`
      }
      return currentNoLiveMatchWhen
    },
    'today',
  )

  const noLiveMatchDateSelectRef = useRef<HTMLInputElement>(null)

  return (
    <>
      <Main {...props}>
        {!openingType ? null : (
          <ModalBox
            show={openingType}
            onCancel={() => setOpeningType(false)}
            //backdrop
            noControl
            noHeader
            scrollable
            autoFocus
            keyboard
          >
            <Type />
          </ModalBox>
        )}
        <section>
          <section id="msg">{/* <?php echo userdata("msg"); ?> */}</section>

          <ul className="rslides">
            <li>
              <img src="../assets/img/slider/betclue1.jpg" alt="IseBaba Slider 1" />
            </li>
            <li>
              <img src="../assets/img/slider/betclue2.jpg" alt="IseBaba Slider 2" />
            </li>
            <li>
              <img src="../assets/img/slider/betclue3.jpg" alt="IseBaba Slider 3" />
            </li>
            <li>
              <img src="../assets/img/slider/betclue4.jpg" alt="IseBaba Slider 4" />
            </li>
            <li>
              <img src="../assets/img/slider/betclue5.jpg" alt="IseBaba Slider 5" />
            </li>
          </ul>

          <div className="col-xs-12 no-padding top-2x">
            <div className="box no-border">
              <div className="box-header">Live Events</div>
              <MatchView className="gain-header" league={activeLeague} live />
            </div>

            <div className="box no-border top-2x">
              <div className="box-header">Upcoming Events</div>

              <div className="box-body no-padding">
                <div className="nav-tabs-custom-dark">
                  <ul className="nav nav-tabs">
                    <li className={noLiveMatchWhen == 'today' ? 'active' : ''}>
                      <a onClick={() => setNoLiveMatchWhen('today')} href="#">
                        Today
                      </a>
                    </li>
                    <li className={noLiveMatchWhen == 'tommorrow' ? 'active' : ''}>
                      <a href="#" onClick={() => setNoLiveMatchWhen('tommorrow')}>
                        Tomorrow
                      </a>
                    </li>
                    <li className={noLiveMatchWhen != 'today' && noLiveMatchWhen != 'tommorrow' ? 'active' : ''}>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault()
                          noLiveMatchDateSelectRef.current?.click()
                        }}
                        className="top-pad-1x"
                        id="fixtures"
                        data-toggle="tooltip"
                        title="Fixtures"
                      >
                        <i className="fa fa-calendar"></i>
                      </a>
                    </li>
                    <li>
                      <input
                        ref={noLiveMatchDateSelectRef}
                        type="date"
                        onChange={(e) => setNoLiveMatchWhen(e.target.value)}
                      />
                    </li>
                  </ul>
                </div>

                <MatchView
                  className="gain-header"
                  league={activeLeague}
                  when={noLiveMatchWhen}
                  noContainer
                  live={false}
                />
              </div>
            </div>
          </div>
        </section>
      </Main>
    </>
  )
}
export default Dashboard
