import React, { useMemo } from 'react'
import IMatch from '../../../../interface/IMatch'
import { useOddGrouping } from '../../../../scripts/hooks/hookCollection'
import OddButton from './OddButton'
import NameScore from './NameScore'

export default function OddPredictionContainer({ match, index }: { match: IMatch; index: number }) {
  const { otherGroupsToSelectKeys, otherSelectedCategory, setOtherSelectedCategory, displayingPartitionOddGroups } =
    useOddGrouping(match._id)

  const oddEntries = useMemo(() => {
    if (!displayingPartitionOddGroups) {
      return []
    }
    const entries = Object.entries(displayingPartitionOddGroups)
    return entries
  }, [displayingPartitionOddGroups])

  return (
    <>
      {index != 0 ? null : (
        <div className="col-xs-12 text-black top-2x no-padding">
          <div className="box no-border">
            <>
              <div className="row text-center top-2x">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4"></div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4"></div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                  <select
                    className="form-control"
                    value={otherSelectedCategory}
                    onChange={(e) => setOtherSelectedCategory(e.target.value)}
                  >
                    <option key={'otherGroupsToSelect'} value={''}>
                      +{otherGroupsToSelectKeys.length}
                    </option>
                    {otherGroupsToSelectKeys.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row text-center top-2x">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-7"></div>
                {oddEntries.map(([category], index) => (
                  <div
                    key={category}
                    className={`col-lg-3 col-md-3 col-sm-3 ${index == 0 ? 'col-xs-5' : 'col-xs-12 sm-0'}`}
                  >
                    {category}
                  </div>
                ))}
              </div>
              <hr className="no-margin" style={{ padding: '2px' }} />
              <div className="row text-center">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-7"></div>
                {oddEntries.map(([category, predictions], index) => (
                  <div
                    key={category}
                    className={`col-lg-3 col-md-3 col-sm-3 ${index == 0 ? 'col-xs-5' : 'col-xs-12 sm-0'}`}
                  >
                    {Object.keys(predictions).map((predictionName) => (
                      <div key={predictionName} className="col-xs-4">
                        {predictionName}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </>
          </div>
        </div>
      )}

      <div className="col-xs-12 top-2x fixtures-wrapper">
        <div className="row fixture">
          <NameScore {...{ match }} />

          {oddEntries.map(([categoryName, category], index) => (
            <div
              key={categoryName}
              className={`col-lg-3 col-md-3 col-sm-3 ${index == 0 ? 'col-xs-5' : 'col-xs-12 sm-0'}`}
            >
              <div className="btn-group" style={{ width: '100%' }}>
                {Object.values(category).map((odd) => (
                  <div key={odd._id} className="col-xs-4">
                    <OddButton {...{ odd }} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
