/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Route } from 'react-router-dom'
import Authenticate from '../pages/pages_components/Authenticate'
import PageHandler from '../pages/pages_components/PageHandler'
import routes from './config/routes'

export enum RoutingType {
  INSECURE,
  SECURE,
}

class Routing {
  __getRoutes: (type: RoutingType) => any
  routers!: any[]
  routeComponents: any
  constructor() {
    this.__getRoutes = (type) => {
      let tempType = ''
      switch (type) {
        case RoutingType.SECURE:
          tempType = 'secure'
          break

        case RoutingType.INSECURE:
        default:
          tempType = 'insecure'
          break
      }
      this.routers = routes[tempType]
      this.routeComponents = this.routers.map((prop) => {
        return !prop.component ? null : (
          <Route
            path={prop.path}
            Component={(cprops) =>
              tempType === 'insecure' ? (
                <PageHandler Component={prop.component} {...cprops} />
              ) : (
                <PageHandler Component={Authenticate} AuthPage={prop.component} {...cprops} />
              )
            }
            key={prop.path}
          />
        )
      })
      return this.routeComponents
    }
  }

  getSecuredRoutes() {
    return this.__getRoutes(RoutingType.SECURE)
  }

  getInSecuredRoutes() {
    return this.__getRoutes(RoutingType.INSECURE)
  }
}

export default Routing
