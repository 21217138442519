/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import RestCode from '../scripts/config/RestApiConstants.json'
import ModalBox from '../components/general/Modal'
import SendVerificationLink from './pages_components/SendVerificationLink'
import Spinner from '../components/general/Spinner'
import SharedConfig from '../scripts/SharedConfig'
import { API_VERSION, BASE, GOAUTH } from '../scripts/config/RestEndpoints'

function Login({ setActive = () => {}, authenticate = () => {} }: any) {
  const [showPassword, setShowing] = useState(false)
  const [logging, setLogging] = useState(false)

  const [unverified, setUnverified] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [gSigning, setGSigning] = useState(false)

  function togglePassword(e: any) {
    e.preventDefault()
    setShowing((previous) => !previous)
  }

  function oauth() {
    setGSigning(true)
    const url = BASE + '/' + API_VERSION + GOAUTH
    window.location.href = url
  }

  async function login(e: { preventDefault: () => void }) {
    setLogging(true)
    e.preventDefault()
    const data = {
      email,
      password,
    }
    let returns
    try {
      returns = await authenticate(data.email, data.password)
    } catch (error) {
      toast.error((error as any).message)
      setLogging(false)
      return
    }
    if (!returns?.status) {
      toast.error(returns?.message || 'Error')
      if (returns?.code === RestCode.EMAIL_UNV.code) {
        setUnverified(true)
      }
    } else {
      setActive(true)
    }
    setLogging(false)
  }

  return (
    <>
      <ModalBox show={unverified} onAccept={() => setUnverified(false)} noControl noHeader backdrop>
        <SendVerificationLink email={email} setShowModal={setUnverified} />
      </ModalBox>
      <div className="">
        <h3 className="text-center">Sign In to your {SharedConfig.getSessionData('SITE_TITLE')}.</h3>
        <br />
        <div className="padding-3x">
          <div className="panel panel-default no-curve">
            <div className="panel-body">
              <form onSubmit={login}>
                <div className="row">
                  <div className="col-xs-12">
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                    </div>

                    <div className="input-group">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <div
                        className="input-group-addon cursor-pointer"
                        onClick={(e) => {
                          togglePassword(e)
                        }}
                      >
                        <span className="input-group-text">
                          <i className={`bx bx-${showPassword ? 'show' : 'hide'}`}></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3 d-flex padding-1x">
                  <Spinner loading={logging || gSigning} loadingText={'Logging in ...'}>
                    <button
                      className="btn btn-primary d-grid w-100 no-curve no-border pull-right"
                      type="submit"
                      disabled={logging}
                    >
                      Sign in
                    </button>

                    <Button
                      variant="outline-success"
                      onClick={oauth}
                      className="fa fa-xxl text-red mx-1 fw-bold text-bold btn btn-outline-success py-0 px-3"
                      style={{
                        fontSize: 'x-large',
                        borderRadius: '20px',
                      }}
                    >
                      <i className="fa-brand fa-google"></i>
                    </Button>
                  </Spinner>
                </div>
              </form>
              <p className="text-center">
                <span>New on our platform? </span>
                <Link to="/signup">
                  <span>Create an account</span>
                </Link>
              </p>
              <hr />
              <Link to="/forget-password">
                <small>Forgot Password?</small>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
