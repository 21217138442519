/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { useLeagues } from '../scripts/hooks/hookCollection'
import MatchSearcher from './layout_components/MatchSearcher'
import SidebarButton from './layout_components/SidebarButton'

function Sidebar({ redirect }: any) {
  const [leagues, activeLeague, setActiveLeague, previousLink, nextLink, setUrl] = useLeagues()

  return (
    <>
      <aside className="main-sidebar text-white">
        <section className="sidebar">
          <MatchSearcher />

          <ul className="sidebar-menu top-2x">
            <li className="header">TOP LEAGUES</li>

            {leagues.map((league) => {
              if (!redirect) {
                return (
                  <SidebarButton
                    active={activeLeague == league._id}
                    onClick={() => {
                      setActiveLeague(league._id)
                    }}
                    name={league.name}
                    key={league._id}
                  />
                )
              } else return null
            })}
            <li>
              <button onClick={() => setUrl(previousLink)}> {'<<'} </button>
              <button onClick={() => setUrl(nextLink)}> {'>>'} </button>
            </li>
          </ul>
        </section>
      </aside>
    </>
  )
}

export default Sidebar
