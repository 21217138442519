/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

const AlertMsg = ({ type, msg, dis = 0 }: any) => {
  return (
    <>
      {dis != 0 ? (
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className={`alert ${type} alert-dismissable`}>
              <button type="button" className="close" data-dismiss="alert" aria-hidden="true">
                &times;
              </button>
              <div dangerouslySetInnerHTML={{ __html: msg }}></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <div className={`alert ${type}`}>
              <div dangerouslySetInnerHTML={{ __html: msg }}></div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AlertMsg
