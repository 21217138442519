/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import MatchView from '../../components/tabs/match/match_tab_components/MatchView'

function MatchSearcher() {
  const [inputValue, setInputValue] = useState('')
  const [search, setSearch] = useState(null)
  const [display, setDisplay] = useState(false)

  const searchTimeoutRef: any = useRef(null)

  const handleSearch = (iv: any) => {
    clearTimeout(searchTimeoutRef.current)
    setSearch(iv)
    showTradeView()
  }

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value)
    clearTimeout(searchTimeoutRef.current)
    searchTimeoutRef.current = setTimeout(() => {
      handleSearch(e.target.value)
    }, 500)
  }
  function showTradeView() {
    if (display) return
    setDisplay(true)
  }

  function hideTradeView() {
    setDisplay(false)
  }

  return (
    <>
      <div className="sidebar-form">
        <div>
          <input
            type="text"
            name="q"
            className="form-control"
            placeholder="Search..."
            value={inputValue}
            onChange={handleInputChange}
          />
        </div>
      </div>

      {display ? (
        <div
          style={{
            backgroundColor: 'black',
            color: 'white',
            position: 'fixed',
            margin: '15px',
            borderRadius: '20px',
            boxShadow: '0px 0px 15px 0px',
            transition: 'all 50ms cubic-bezier(0, 0, 0.2, 1) 10ms',
            zIndex: '12321',
          }}
        >
          <Button
            size="sm"
            variant="warning"
            onClick={hideTradeView}
            style={{
              position: 'absolute',
              right: '0px',
              margin: '10px',
              marginTop: '-10px',
              zIndex: '100000',
            }}
            className="utilityLink rounded"
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </Button>
          <MatchView className="gain-header" search={search} />
        </div>
      ) : null}
    </>
  )
}

export default MatchSearcher
