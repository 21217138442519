import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'

export default function NotFound() {
  return (
    <>
      <Container fluid>
        <div className="misc-wrapper">
          <h2 className="mb-2 mx-2">Page Not Found :(</h2>
          <p className="mb-4 mx-2">Oops! 😖 The requested page was not found.</p>
          <Link to="/" className="btn btn-primary">
            Home
          </Link>
          <div className="mt-3">
            <img
              src={'../assets/img/illustrations/page-misc-error-light.png'}
              alt="Error"
              width="500"
              className="img-fluid"
              data-app-dark-img={'../assets/img/illustrations/page-misc-error-light.png'}
              data-app-light-img={'../assets/img/illustrations/page-misc-error-light.png'}
            />
          </div>
        </div>
      </Container>
    </>
  )
}
