/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

function SidebarButton({
  active = false,
  name = '',
  onClick = (e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e?.preventDefault()
  },
}) {
  return (
    <li title={`${name}`} className={`${active ? 'active' : ''}`}>
      <a
        href="javascript:void"
        className={`overflow`}
        onClick={(e) => {
          e.preventDefault()
          onClick(e)
        }}
      >
        {` ${name}`}
      </a>
    </li>
  )
}

export default SidebarButton
