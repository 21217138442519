/* eslint-disable @typescript-eslint/no-explicit-any */
import Fetcher from './Fetcher'

class SharedFetcher extends Fetcher {
  static instance: any
  constructor() {
    if (!SharedFetcher.instance) {
      super()
      SharedFetcher.instance = this
    }
    return SharedFetcher.instance
  }
}

const fetcher = new SharedFetcher()
export default fetcher
