/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Card, Col } from 'react-bootstrap'
import { useHistoryButton } from '../../../scripts/hooks/hookCollection'
import DepositHistory from './deposit_tab_components/DepositHistory'
import DepositGenerateFragment from './deposit_tab_components/DepositGenerateFragment'
import DepositForm from './deposit_tab_components/DepositForm'
import { IAny } from '../../../interface/IAny'

function DepositTab(props: any) {
  const [data, setData] = useState<IAny>({} as any)
  const [history, , historyButton] = useHistoryButton()

  return (
    <Col md="12" className="mt-3">
      <Card>
        <Card.Header>
          <h4>Deposit {historyButton as any}</h4>
        </Card.Header>
        <Card.Body>
          {history ? (
            <DepositHistory />
          ) : data !== null ? (
            <>
              <DepositGenerateFragment
                {...props}
                walletAddress={data.address}
                description={data.description}
                transactionId={data._id}
                setData={(element: React.SetStateAction<IAny>) => setData(element)}
              />
            </>
          ) : (
            <DepositForm {...props} setData={(element: React.SetStateAction<IAny>) => setData(element)} />
          )}
        </Card.Body>
      </Card>
    </Col>
  )
}

export default DepositTab
