/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import SharedConfig from '../../scripts/SharedConfig'
import fetcher from '../../scripts/SharedFetcher'
import { UID } from '../../scripts/config/contants'
import { BASE, LOGIN } from '../../scripts/config/RestEndpoints'
import Login from '../Login'
import Dashboard from '../Dashboard'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import IResStruct from '../../interface/IResStruct'
import IUser from '../../interface/IUser'
import { getDataUrlFromUrl } from '../../scripts/misc'

function Authenticate(props: any) {
  const [oauth, setOauth] = useState(props.oauth)
  const [active, setActive] = useState(hasActiveSession())
  const AuthPage = props.AuthPage || Dashboard
  const authPageProps = { ...props, AuthPage: undefined }

  useEffect(() => {
    if (oauth) {
      authenticate('', '')
    } else {
      const isActive = hasActiveSession()
      if (isActive && isActive !== active) {
        setActive(isActive)
      }
    }
  })

  async function authenticate(email: string, password: string, session = true) {
    return await new Promise((resolve) => {
      const authData = {
        url: oauth ? BASE + '/auth/verify/' + window.location.search : LOGIN,
        data: { email, password },
      }

      const returns = {
        status: false,
        message: '',
        code: 0,
      }

      fetcher
        .fetch<IResStruct>(authData)
        .then((data: IResStruct) => {
          if (data.data?.status) {
            if (session) {
              SharedConfig.setLocalData(UID, data.connection.uid)
              SharedConfig.setLocalData('auth', data.data.token)
              SharedConfig.setLocalData('auth_time', btoa(`${Date.now()}`))
              const user: IUser = {
                ...data.data.user,
                avatar: getDataUrlFromUrl(data.data.user.avatar),
              }
              SharedConfig.setLocalData('user', user)
            }
            returns.status = true
            if (oauth) {
              setActive(true)
              setOauth(false)
            }
          }
          returns.message = data?.data?.message || ''
          returns.code = data?.connection?.errorCode as any
          oauth && !data.data?.status && toast.error(data?.data?.message)
        })
        .catch((error) => {
          returns.message = error.message
          oauth && toast.error(error.message)
        })
        .finally(() => {
          !returns.status && oauth && setOauth(false)
          !returns.message && (returns.message = 'Error')
          resolve(returns)
        })
    })
  }

  function hasActiveSession() {
    const uid = SharedConfig.getLocalData(UID)
    const auth = SharedConfig.getLocalData('auth')
    const authTime = SharedConfig.getLocalData('auth_time')
    const authTimeD = authTime && atob(authTime)
    const authTimeDiff = authTimeD && Date.now() - authTimeD
    const day30 = 1000 * 60 * 60 * 24 * 30
    const isActive = uid && auth && auth.trim().length !== 0 && authTimeDiff && authTimeDiff < day30
    return isActive
  }

  return (
    <>
      {oauth ? (
        <Spinner />
      ) : active ? (
        <AuthPage {...authPageProps} user={SharedConfig.getLocalData('user')} />
      ) : (
        <Login setActive={setActive} authenticate={authenticate} />
      )}
    </>
  )
}

export default Authenticate
