/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { WS_BASE, ALL_MATCHES } from '../../../../scripts/config/RestEndpoints'
import PaginatedTable, { DESCENDING } from '../../../paginating/PaginatedTable'
import SharedConfig from '../../../../scripts/SharedConfig'
import OddPredictionContainer from './OddPredictionContainer'
import IMatch from '../../../../interface/IMatch'
import { IAny } from '../../../../interface/IAny'
import { getDayString } from '../../../../common'

function TV({
  live,
  size,
  league,
  when,
  search,
  matchUpdated,
  className,
  style,
  noScroll = true,
  hidePaginator = false,
}: {
  size?: number
  live?: string
  league?: string
  when?: string
  search?: string
  matchUpdated?: any
  className?: string
  style?: IAny
  noScroll?: boolean
  hidePaginator?: boolean
}) {
  const [reload] = useState(false)

  const urlRef = useRef(ALL_MATCHES)
  const fieldRef = useRef({
    matchEvent: {
      name: '',
      type: Number,
      transform: {
        out: (match: IMatch, index: number) => <OddPredictionContainer {...{ match, index }} />,
      },
      hideFromSearch: true,
      virtual: true,
    },
  })

  const query = useMemo(() => {
    const q: IAny = {
      isLive: live,
      populate: ['leagueId'],
    }
    if (search) {
      q.name = search
    } else {
      if (league) q.leagueId = league
      if (when) {
        q['matchDate.dateString'] = getDayString(when)
      }
    }
    return q
  }, [search, live, league, when, matchUpdated])

  return (
    <>
      {!search && !league ? null : (
        <PaginatedTable
          style={{
            tableStyle: {
              fontSize: '11px',
              backgroundColor: '#2b2d36',
              color: 'white',
              ...style,
            },
            tdStyle: {
              padding: '5px',
            },
          }}
          className={{ tdClass: 'text-center', tableStyle: className || '' }}
          url={urlRef.current}
          dataName="matches"
          fields={fieldRef.current}
          primaryKey="updatedAt.date"
          sortOrder={DESCENDING}
          forCurrentUser={false}
          reload={matchUpdated || reload}
          query={query}
          size={size}
          noControl
          noScroll={noScroll}
          noDoubleClick
          noClickOpen
          hidePaginator={hidePaginator}
          numbered={false}
          striped={false}
          hover={false}
        />
      )}
    </>
  )
}

function MatchView(props: any) {
  const [matchUpdated, setMatchUpdated] = useState('')

  useEffect(() => {
    const socket = new WebSocket(WS_BASE)

    socket.onmessage = (event) => {
      Number.parseInt(event?.data) && setMatchUpdated(event.data)
    }

    socket.onopen = () => {
      socket.send(SharedConfig.getLocalData('auth'))
    }

    return () => {
      socket.close()
    }
  }, [])

  return (
    <>
      <TV {...props} matchUpdated={matchUpdated} />
    </>
  )
}

export default MatchView
