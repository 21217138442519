/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SEND_VERIFICATION, VERIFY_MAIL } from '../../scripts/config/RestEndpoints'
import fetcher from '../../scripts/SharedFetcher'
import Spinner from '../../components/general/Spinner'
import IResStruct from '../../interface/IResStruct'

function SendVerificationLink(props: any) {
  const [email, setEmail] = useState(props.email)
  const [code, setCode] = useState('')
  const [resend, setResend] = useState(false)
  const [verifying, setVerifying] = useState(false)
  const [verified] = useState(false)
  const [rt, setRt] = useState(true)

  async function verifyCode(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setVerifying(true)
    const postData = {
      url: VERIFY_MAIL,
      data: { email, code },
    }
    fetcher
      .fetch<IResStruct>(postData)
      .then((verify) => {
        if (verify?.data.status) {
          toast.success(verify.data.message)
          props.setShowModal(false)
        } else {
          toast.error(verify?.data.message)
        }
        setVerifying(false)
      })
      .catch((error) => {
        toast.error(error.message)
        setVerifying(false)
      })
  }

  async function resendVerificationCode(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault()
    if (!email) {
      toast.error('Provide email')
      return
    }
    setResend(true)
    fetcher
      .fetch<IResStruct>(SEND_VERIFICATION + email)
      .then((sendVerificationMail) => {
        if (sendVerificationMail?.data.status) {
          setTimeout(() => {
            setRt(true)
          }, 1000 * 10)
          setRt(false)
          toast.success(sendVerificationMail.data.message)
        } else {
          toast.error(sendVerificationMail?.data.message)
        }
        setResend(false)
      })
      .catch((error) => {
        toast.error(error.message)
        setResend(false)
      })
  }

  return verified ? (
    <Navigate to="/login" />
  ) : (
    <>
      <Card>
        <Card.Title className="text-center p-2 mt-2">Verification</Card.Title>
        <Card.Body>
          <form id="formAuthentication" className="mb-3" onSubmit={(e) => verifyCode(e)}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Email address"
                autoFocus={true}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="mb-2">
                <button
                  onClick={(e) => {
                    resendVerificationCode(e)
                  }}
                  className="btn btn-link"
                  disabled={resend && !rt}
                >
                  <Spinner loading={resend} loadingText="Resending" text="Resend Code" />
                </button>
              </div>
            </div>
            <div className="mb-3 form-password-toggle">
              <div className="d-flex justify-content-between ">
                <label className="form-label" htmlFor="code">
                  Confirmation Code
                </label>
              </div>
              <div className="input-group input-group-merge">
                <input
                  type={'text'}
                  className="form-control"
                  placeholder="Code"
                  aria-describedby="password"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <button className="btn btn-primary d-grid w-100" type="submit" disabled={verifying}>
                <Spinner loading={verifying} loadingText="Verifying" text="Verify" />
              </button>
            </div>
          </form>
        </Card.Body>
      </Card>
    </>
  )
}

export default SendVerificationLink
