/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-jsdoc */
import fetcher from './SharedFetcher'
import { toast } from 'react-toastify'
import SharedConfig from './SharedConfig'
import Fetcher from './Fetcher'
import IResStruct from '../interface/IResStruct'

// Large screens breakpoint
export const LAYOUT_BREAKPOINT = 1200

export const isActivePath = (location: { pathname: any }, routeName: string) => {
  const path = location.pathname
  const splitted = path.split('/')
  for (let i = 0; i < splitted.length; i++) {
    const segment = splitted[i]
    const routing = routeName.split('/')[1]
    if (segment === routing) {
      return 'active'
    }
  }
  return null
}

export async function getSupportedToken(type: string) {
  const data = await fetcher.fetch<IResStruct>(`/${type}/supported-tokens`)
  if (data) {
    if (data.connection.statusCode !== 200) {
      toast.error(data.data?.message)
      return []
    }
    return data.data?.tokens
  } else {
    toast.error('Error fectching token. ')
  }
  return {}
}

export function redirect(url: any) {
  if (url instanceof Function) {
    SharedConfig.addTo('redirectListeners', url)
  } else {
    const redirectListeners = SharedConfig.getSessionData('redirectListeners')
    if (redirectListeners instanceof Array) {
      redirectListeners.forEach((fn) => fn(url))
    } else {
      redirectListeners(url)
    }
  }
}

export function objectEquals(obj1: { [x: string]: any } | null, obj2: { [x: string]: any } | null) {
  // Check if both object are strictly equal
  if (obj1 === obj2) {
    return true
  }

  // Check if either object is null or not
  if (typeof obj1 !== 'object' || obj1 == null || typeof obj2 !== 'object' || obj2 == null) {
    return false
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false
  }

  // Iterate through the keys and recursively check for equality
  for (const key of keys1) {
    if (!keys2.includes(key) || !objectEquals(obj1[key], obj2[key])) {
      return false
    }
  }
  return true
}

export function encodeQuery(object = {}) {
  return btoa(JSON.stringify(object))
}

export const paginatingUrl = (url: string, data: { [k: string]: any }, size = 10) => {
  const dataString = encodeQuery(data)
  return `${url}?q=${dataString}${size ? `&size=${size}` : ''}`
}

export async function getDataUrl(data: any) {
  return await new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()
      reader.readAsDataURL(data)
      reader.onloadend = () => {
        const uri = reader.result
        resolve(uri)
      }
    } catch (err) {
      reject(err)
    }
  })
}

export async function getDataUrlFromUrl(url: string) {
  return await new Promise((resolve, reject) => {
    async function runner() {
      try {
        const imo = {
          url,
          method: 'GET',
        }
        const idata = await fetcher.fetch(imo, Fetcher.RETURN_BLOB)
        const uri = await getDataUrl(idata)
        resolve(uri)
      } catch (err) {
        reject(err)
      }
    }
    runner()
  })
}

export const getCurrentUrl = (includeHostName = false) => {
  const url = window.location.href
  if (includeHostName) {
    return url
  }
  const noScheme = url?.split('//')[1]
  const pathWithoutShemeAndHostname = noScheme?.split('/')[1]
  return `../${pathWithoutShemeAndHostname}`
}

export const isMobileDevice = () => {
  return typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1
}

export const isSmallScreen = () => {
  return (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < LAYOUT_BREAKPOINT
}

export const isMobileScreen = () => {
  return (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 425
}
