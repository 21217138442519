import { IAny } from '../../interface/IAny'
import AboutUs from '../../pages/AboutUs'
import ComingSoon from '../../pages/ComingSoon'
import Dashboard from '../../pages/Dashboard'
import GoogleAuth from '../../pages/GoogleAuth'
import PrivacyPolicy from '../../pages/PrivacyPolicy'
import Referral from '../../pages/Referral'
import Refresh from '../../pages/Refresh'
import Setting from '../../pages/Setting'
import TermsAndConditions from '../../pages/TermsAndConditions'
import Upgrade from '../../pages/Upgrade'
import Wallet from '../../pages/Wallet'

export interface RouteType {
  [k: string]: Route[]
}

export interface Route {
  path: string
  component: (props: IAny) => React.JSX.Element
}

const routes: RouteType = {
  insecure: [
    {
      path: '/auth/verify',
      component: GoogleAuth,
    },
    {
      path: '/terms-and-conditions',
      component: TermsAndConditions,
    },
    {
      path: '/privacy-policy',
      component: PrivacyPolicy,
    },
    {
      path: '/about-us',
      component: AboutUs,
    },
    {
      path: '/refresh',
      component: Refresh,
    },
    {
      path: '/',
      component: Dashboard,
    },
  ],

  secure: [
    {
      path: '/wallet',
      component: Wallet,
    },
    {
      path: '/setting',
      component: Setting,
    },
    {
      path: '/license',
      component: Upgrade,
    },
    {
      path: '/referral',
      component: Referral,
    },
    {
      path: '/reward',
      component: ComingSoon,
    },
    {
      path: '/loan',
      component: ComingSoon,
    },
    {
      path: '/academy',
      component: ComingSoon,
    },
  ],
}

export default routes
