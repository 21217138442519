/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LogOut from '../pages/pages_components/LogOut'
import SharedConfig from '../scripts/SharedConfig'
import fetcher from '../scripts/SharedFetcher'
import { WALLET_BALANCE } from '../scripts/config/RestEndpoints'
import IResStruct from '../interface/IResStruct'
import accounting from 'accounting'

function Header({ user }: any) {
  const [fundingWallet, setFundingWallet] = useState('')

  useEffect(() => {
    fetcher.fetch<IResStruct>(WALLET_BALANCE + user?._id).then((data) => setFundingWallet(data?.data?.balance || 0))
  }, [])

  return (
    <>
      <header className="main-header">
        <Link to="/" className="logo">
          <span className="logo-mini">
            <img
              src={SharedConfig.getSessionData('SITE_FAVICON') || '../assets/img/logo-sm.png'}
              style={{ width: '100%', height: 'auto' }}
            />
          </span>
          <span className="logo-lg">
            <img
              src={SharedConfig.getSessionData('SITE_LOGO') || '../assets/img/LOGO_LG.png'}
              style={{ width: '175px', height: '38px' }}
            />
          </span>
        </Link>

        <nav className="navbar navbar-static-top">
          <a href="#" className="sidebar-toggle" data-toggle="offcanvas" role="button">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </a>
          <div className="navbar-custom-menu" style={{ marginTop: '0px' }}>
            {!user ? (
              <ul className="nav navbar-nav">
                <li>
                  <Link to="/signup">Create An Account</Link>
                </li>
                <li>
                  <Link to="/login" title="Sign In">
                    Sign In <i className="fa fa-sign-in"></i>
                  </Link>
                </li>
              </ul>
            ) : (
              <ul className="nav navbar-nav">
                <li>
                  <Link to="../wallet?tab=deposit">
                    Top up <i className="fa fa-plus-square"></i>{' '}
                  </Link>
                </li>
                <li>
                  <Link to="../wallet">{accounting.formatMoney(fundingWallet, '$', 2)}</Link>
                </li>
                <li className="dropdown user user-menu">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                    <img src={user?.avatar as string} className="user-image" alt="User Image" />
                    <span className="hidden-xs">{user?._id}</span>
                  </a>
                  <ul className="dropdown-menu">
                    <li className="user-header">
                      <img src={user?.avatar as string} className="img-circle" alt="User Image" />

                      <p>
                        {user?._id} <br /> @ {user?.firstname}
                        <small data-toggle="tooltip" title="My referral link">
                          <i className="fa fa-link"></i> <Link to={'../referral'}>Referral</Link>
                        </small>
                      </p>
                    </li>
                    {/* <li className="user-body">
                        <div className="row">
                            <div className="col-xs-4 text-center">
                                <a href="#">Referrals <br> <?php echo $this->Util_model->row_count("user_profile","WHERE ref=".userdata(UID)); ?></a>
                            </div>
                            <div className="col-xs-4 text-center">
                                <a href="#">Following <br> <?php echo $this->Main_model->count_followers_following(userdata(UID),'following'); ?></a>
                            </div>
                            <div className="col-xs-4 text-center">
                                <a href="#">Subs <br> <?php echo $this->Util_model->row_count("user_subscription","WHERE uid=".userdata(UID)." AND subStatus=1"); ?></a>
                            </div>
                        </div>
                        <!-- /.row -->
                    </li> */}
                    <li className="user-footer">
                      <div className="pull-left">
                        <Link to="../setting" className="btn btn-default btn-flat">
                          <i className="fa fa-user"></i> Profile
                        </Link>
                      </div>
                      <div className="pull-right">
                        <span className="btn btn-default btn-flat">
                          <LogOut /> <i className="fa fa-power-off"></i>
                        </span>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header
