/* eslint-disable @typescript-eslint/no-explicit-any */
import { SITE_TITLE } from '../scripts/config/contants'
import React, { Component } from 'react'
import Docker from './layout_components/Docker'
import SharedConfig from '../scripts/SharedConfig'
import { isMobileScreen } from '../scripts/misc'

class Footer extends Component {
  constructor(props: any) {
    super(props)
    this.state = { smallScreen: false }
    this.resizeListener.bind(this)
  }

  resizeListener() {
    const isMobile = isMobileScreen()
    this.setState({ smallScreen: isMobile })
  }

  componentDidMount() {
    this.setState({ smallScreen: isMobileScreen() })
    window.onresize = () => this.resizeListener()
  }

  render() {
    return (
      <>
        {!(this.state as any).smallScreen ? (
          <>
            <footer className="main-footer text-white sm-0" style={{ backgroundColor: '#363944', borderTop: 'none' }}>
              {SharedConfig.getSessionData('SITE_TITLE') || SITE_TITLE} is not affiliated or connected with sports
              teams, event organisers and/or players displayed on its images/websites.
              <span className="pull-right">
                {new Date().getFullYear()} &copy; <b>{SharedConfig.getSessionData('SITE_TITLE') || SITE_TITLE}</b>
              </span>
              {(this.props as any).children}
            </footer>

            <div
              className="col-xs-12 no-display sm-1 text-center"
              style={{
                marginBottom: '50px',
                padding: '5px 0px',
                backgroundColor: '#f6f6f6',
              }}
            >
              <b>18+</b> Bet responsibly
            </div>
          </>
        ) : (
          <Docker {...this.props} />
        )}
      </>
    )
  }
}
export default Footer
