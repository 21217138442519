/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import Main from '../layout/Main'
import { Container } from 'react-bootstrap'
import SharedConfig from '../scripts/SharedConfig'

function AboutUs(props: any) {
  const [about, setAbout] = useState('')

  useEffect(() => {
    setTimeout(
      () =>
        setAbout(SharedConfig.getSessionData('ABOUT_US') || 'Could not load the requested data please try again later'),
      500,
    )
  }, [])

  return (
    <>
      <Main {...props} noHeader noFooter>
        <Container fluid>
          <div
            dangerouslySetInnerHTML={{
              __html: about,
            }}
          ></div>
        </Container>
      </Main>
    </>
  )
}

export default AboutUs
