/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { ALL_TRANSACTION } from '../../../../scripts/config/RestEndpoints'
import PaginatedTable, { DESCENDING } from '../../../paginating/PaginatedTable'
import accounting from 'accounting'

function DepositHistory() {
  const url = ALL_TRANSACTION

  const fields = {
    address: { name: 'Wallet Address', type: String },
    preBalance: { name: 'Balance Before', type: Number },
    actualAmount: {
      name: 'Transaction Token Amount',
      type: Number,
      transform: {
        out: (row: {
          actualAmount: number
          token: {
            symbol:
              | string
              | number
              | boolean
              | React.ReactElement<any, string | React.JSXElementConstructor<any>>
              | Iterable<React.ReactNode>
              | React.ReactPortal
              | null
              | undefined
          }
        }) => (
          <>
            <div className="fw-bold">
              {accounting.formatNumber(row?.actualAmount, 2)}
              {row?.token?.symbol}
            </div>
          </>
        ),
      },
    },
    resolvedAmount: {
      name: 'Default Token Amount',
      type: Number,
      transform: {
        out: (row: { resolvedAmount: number }) => (
          <>
            <div className="fw-bold">{accounting.formatNumber(row?.resolvedAmount, 2)}USDT</div>
          </>
        ),
      },
    },
    type: { name: 'Type', type: String },
    hash: { name: 'Transaction Hash ID', type: String },
    description: { name: 'Description', type: String },
    status: { name: 'Status', type: String },
    'createdAt.date': { name: 'Created', type: Date },
  }

  const query = {
    type: 'deposit',
    populate: ['token'],
  }

  return (
    <PaginatedTable
      url={url}
      dataName="transactions"
      fields={fields as any}
      query={query}
      primaryKey="createdAt.date"
      sortOrder={DESCENDING}
    />
  )
}

export default DepositHistory
