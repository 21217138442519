/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Footer from '../layout/Footer'
import Header from '../layout/Header'
import Sidebar from '../layout/Sidebar'
import ThemeToggler from './layout_components/ThemeToggler'
import { ToastContainer } from 'react-toastify'
import AlertMsg from '../components/general/AlertMsg'

function Main(props: any) {
  const user = props?.user

  return (
    <>
      <div className="sidebar-mini fixed hold-transition skin-black">
        <div className="wrapper">
          {props.noHeader ? null : <Header {...props} />}

          <Sidebar {...props} />

          <div className="content-wrapper" style={{ paddingTop: '0px' }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-xs-12">
                  <section className="content-header">
                    <p className="text-bold">
                      <span className="badge bg-red pull-left">
                        <i className="fa fa-clock-o"></i> <span id="clock"></span>
                      </span>
                      &nbsp;&nbsp;
                    </p>
                  </section>
                  {user ? (
                    <AlertMsg
                      msg={`<i class='fa fa-info-circle'></i> Kindly check your email to verify you email and get 1 star. <a href="../users/send_email_verification_link/${user?._id}">Resend email verification link</a>`}
                      type={'alert-warning'}
                      dis={1}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                  <div className="container-xxl flex-grow-1 container-p-y mb-5">
                    <ToastContainer newestOnTop={true} toastStyle={{ borderRadius: 20, padding: 5 }} />
                    {props.children}
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 sm-0">
                  <div className="box no-border">
                    <div className="box-header">
                      <h5>Betslip</h5>
                    </div>
                    <div className="box-body">
                      <h4 className="text-center">Your betslip is empty.</h4>
                      <p className="text-center">Please make one or more selections in order to place a bet.</p>
                      <div className="top-3x"></div>
                      <div className="form-group">
                        <label htmlFor="book">Book:</label>
                        <br />
                        <small>Please insert a booking number below.</small>
                        <div className="input-group">
                          <input type="text" className="form-control" />
                          <div className="input-group-btn">
                            <button className="btn btn-danger">Book</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.noFooter ? null : (
            <Footer>
              <ThemeToggler {...props} />
            </Footer>
          )}
        </div>
      </div>
    </>
  )
}

export default Main
