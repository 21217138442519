/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import DockerButton from './DockerButton'
import { useLeagues } from '../../scripts/hooks/hookCollection'

function Docker({ redirect }: any) {
  const [leagues, activeLeague, setActiveLeague] = useLeagues()

  return (
    <>
      <footer
        className="main-footer no-display sm-1 top-2x"
        style={{
          paddingTop: '0px',
          paddingBottom: '0px',
          position: 'fixed',
          bottom: '0px',
          width: '100%',
          backgroundColor: '#232121',
        }}
      >
        <div className="container-fluid no-padding">
          <ul className="nav navbar-nav text-center no-padding no-margin">
            {leagues.map((league) => {
              if (!redirect) {
                return (
                  <DockerButton
                    active={activeLeague == league._id}
                    onClick={() => setActiveLeague(league._id)}
                    name={league.name}
                    icon={league.icon}
                    key={league._id}
                  />
                )
              } else return null
            })}
          </ul>
        </div>
      </footer>
    </>
  )
}

export default Docker
