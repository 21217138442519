/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Authenticate from './pages_components/Authenticate'

function GoogleAuth(props: any) {
  return (
    <>
      <Authenticate {...props} oauth />
    </>
  )
}

export default GoogleAuth
