import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { SEND_FORGET_PASSWORD_VERIFICATION, VERIFY_FORGET_PASSWORD_MAIL } from '../scripts/config/RestEndpoints'
import fetcher from '../scripts/SharedFetcher'
import Spinner from '../components/general/Spinner'
import { Link, Navigate, useLocation } from 'react-router-dom'
import SharedConfig from '../scripts/SharedConfig'
import PasswordInput from '../components/general/PasswordInput'
import IResStruct from '../interface/IResStruct'

function ForgetPassword() {
  const location = useLocation()
  const search = new URLSearchParams(location.search)

  const vemail = search?.get('vemail') || ''
  const vcode = search?.get('vcode') || ''
  const [email, setEmail] = useState(vemail)
  const [code, setCode] = useState(vcode)
  const [password, setPassword] = useState('')
  const [isPasswordStrong, setIsPasswordStrong] = useState('')
  const [sent, setSent] = useState(false)
  const [resend, setResend] = useState(false)
  const [verifying, setVerifying] = useState(false)
  const [verified, setVerified] = useState(false)
  const [rt, setRt] = useState(true)

  async function verifyCode(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setVerifying(true)
    const postData = {
      url: VERIFY_FORGET_PASSWORD_MAIL,
      data: { email, code, newPassword: password },
    }
    fetcher
      .fetch<IResStruct>(postData)
      .then((verify) => {
        if (verify?.data.status) {
          toast.success(verify.data.message)
          setTimeout(() => {
            setVerified(true)
            setVerifying(false)
          }, 1000 * 5)
        } else {
          toast.error(verify?.data.message)
          setVerifying(false)
        }
      })
      .catch((error) => {
        toast.error(error.message)
        setVerifying(false)
      })
  }

  async function resendVerificationCode(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault()
    if (!email) {
      toast.error('Provide email')
      return
    }
    setResend(true)
    fetcher
      .fetch<IResStruct>(SEND_FORGET_PASSWORD_VERIFICATION + email)
      .then((sendVerificationMail) => {
        if (sendVerificationMail?.data.status) {
          setTimeout(() => {
            setRt(true)
          }, 1000 * 10)
          setRt(false)
          setResend(false)
          setSent(true)
          toast.success(sendVerificationMail.data.message)
        } else {
          setResend(false)
          toast.error(sendVerificationMail?.data.message)
        }
      })
      .catch((error) => {
        setResend(false)
        toast.error(error.message)
      })
  }

  return verified ? (
    <Navigate to="/home" />
  ) : (
    <>
      <div className="padding-3x">
        <div className="panel panel-default no-curve">
          <div className="panel-header text-center my-3">
            <Link to="/">
              <img
                src={SharedConfig.getSessionData('SITE_FAVICON') || '/favicon.ico'}
                height="100"
                width="auto"
                alt={SharedConfig.getSessionData('SITE_TITLE')}
              />
            </Link>
          </div>
          <div className="text-center top-1x">
            <h2 className="text-center p-2 mt-2">Forget Password</h2>
          </div>
          <div className="panel-body">
            <form className="mb-3" onSubmit={(e) => verifyCode(e)}>
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email address"
                  autoFocus={true}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="mb-2">
                  <button
                    onClick={(e) => {
                      resendVerificationCode(e)
                    }}
                    className="btn btn-link"
                    disabled={resend && !rt}
                  >
                    <Spinner loading={resend} loadingText="Resending" text={sent ? 'Resend Code' : 'Send Code'} />
                  </button>
                </div>
              </div>

              <div className="mb-3 form-group">
                <label className="form-label" htmlFor="code">
                  Confirmation Code
                </label>
                <input
                  type={'text'}
                  className="form-control"
                  placeholder="Code"
                  aria-describedby="Code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                />
              </div>

              <div className="mb-3 form-group">
                <label className="form-label" htmlFor="password">
                  New Password
                </label>
                <PasswordInput setPassword={setPassword} setIsPasswordStrong={setIsPasswordStrong} required />
              </div>

              <div className="mb-3 form-group" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <button
                  className="btn btn-primary d-grid w-100"
                  type="submit"
                  disabled={verifying || !isPasswordStrong}
                  title={!isPasswordStrong ? 'Use strong password to continue' : ''}
                >
                  <Spinner loading={verifying} loadingText="Verifying" text="Verify" />
                </button>
              </div>
            </form>
            <hr />
            <p className="text-center text-bold">
              <Link to="/login">
                <span>I just remembered my password</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgetPassword
