/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react'
import { Button, ButtonGroup, Card, Col } from 'react-bootstrap'
import PaginatedTable from '../../paginating/PaginatedTable'
import {
  ALL_PACKAGE,
  CREATE_PACKAGE_TRANSACTION,
  RENEW_PACKAGE_TRANSACTION,
  USER_PLAN,
} from '../../../scripts/config/RestEndpoints'
import fetcher from '../../../scripts/SharedFetcher'
import Spinner from '../../paginating/Spinner'
import { UID } from '../../../scripts/config/contants'
import ModalBox from '../../general/Modal'
import SharedConfig from '../../../scripts/SharedConfig'
import { FaRocket } from 'react-icons/fa'
import { toast } from 'react-toastify'
import HumanizeTimestamp from '../../general/HumanizeTimestamp'
import IResStruct from '../../../interface/IResStruct'
import IPlan from '../../../interface/IPlan'

function UpgradesTab(props: any) {
  const urlRef = useRef(ALL_PACKAGE)
  const [loading, setLoading] = useState(true)
  const [loadingError, setLoadingError] = useState('')
  const [itemId, setItemId] = useState('')
  const [showConfirmUpgrade, setShowConfirmUpgrade] = useState(false)
  const [myPlan, setMyPlan] = useState<IPlan>({} as any)

  const [forRenewal, setForRenewal] = useState(false)

  useEffect(() => {
    const uid = SharedConfig.getLocalData(UID)
    fetcher
      .fetch<IResStruct>(USER_PLAN + uid)
      .then((data) => {
        setLoading(false)
        setMyPlan(data?.data?.plan)
      })
      .catch((error) => {
        setLoading(false)
        setLoadingError(error.message)
      })
  }, [])

  const cardViewRef = (
    rowData: {
      name:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | Iterable<React.ReactNode>
        | React.ReactPortal
        | null
        | undefined
      description: any
    },
    resultIndex: any,
  ) => (
    <Col xs="12" sm="6" lg="6">
      <Card className="m-1">
        <Card.Header className="text-center fw-bold text-dark h1">{rowData?.name}</Card.Header>
        <Card.Body
          dangerouslySetInnerHTML={{
            __html: rowData?.description || rowData?.name,
          }}
        ></Card.Body>
        <Card.Footer>{out(rowData as any, resultIndex)}</Card.Footer>
      </Card>
    </Col>
  )
  async function confirmPackage(packageId: string) {
    const fetchData = {
      url: CREATE_PACKAGE_TRANSACTION,
      data: { packageId, uid: SharedConfig.getLocalData(UID) },
    }
    let data = null
    try {
      data = await fetcher.fetch<IResStruct>(fetchData)
    } catch (er) {
      toast.error((er as any).message)
    }
    if (!data?.data?.status) {
      toast.error(data?.data.message)
    } else {
      setShowConfirmUpgrade(false)
      toast.success(data.data.message)
      props.refresh()
    }
  }

  async function renewPackage() {
    let data
    try {
      data = await fetcher.fetch<IResStruct>(RENEW_PACKAGE_TRANSACTION)
    } catch (er) {
      toast.error((er as any).message)
    }
    if (!data?.data?.status) {
      toast.error(data?.data.message)
    } else {
      setForRenewal(false)
      toast.success(data.data.message)
      props.refresh()
    }
  }

  function out(
    rowData: {
      code: number
      amount:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | Iterable<React.ReactNode>
        | null
        | undefined
      _id: React.SetStateAction<string>
    },
    rowIndex: any,
  ) {
    return (myPlan as any)?.packageId?.code === rowData?.code ? (
      <div className="s-grid">
        <span className="text-white fw-bold badge badge-primary bg-gray p-2 text-center text-capitalize">
          Expires <HumanizeTimestamp timestamp={(myPlan as any)?.expires} />
        </span>
        <span className="text-white fw-bold badge bg-green p-2 text-center mx-1">Your Current License</span>
        <Button variant="danger" size="sm" onClick={() => setForRenewal(true)}>
          Renew
        </Button>
        <ModalBox
          show={forRenewal}
          onCancel={() => setForRenewal(false)}
          onAccept={renewPackage}
          header={<h1 className="text-center">Confirm Renewal</h1>}
          type="danger"
          backdrop
        >
          <span>Please confirm renewing your license</span>
        </ModalBox>
      </div>
    ) : (myPlan?.packageId as any)?.code > rowData?.code ? (
      <>
        <b className="h2 fa fa-xl text-primary">
          <i className="fas fa-dollar"></i>
          {rowData?.amount}
        </b>{' '}
      </>
    ) : (
      <>
        <b className="h2 fa fa-xl text-primary">
          <i className="fas fa-dollar"></i>
          {rowData?.amount}
        </b>
        <ButtonGroup size="sm" className="m-1 pull-right">
          <Button
            onClick={() => {
              setShowConfirmUpgrade(true)
              setItemId(rowData._id)
            }}
            style={{ padding: '5px' }}
            variant="success"
          >
            <FaRocket /> Buy
          </Button>
        </ButtonGroup>
      </>
    )
  }

  return (
    <>
      <ModalBox
        show={showConfirmUpgrade}
        onCancel={() => setShowConfirmUpgrade(false)}
        onAccept={() => confirmPackage(itemId)}
        header={<h1 className="text-center">Confirm Purchase</h1>}
        type="danger"
        backdrop
      >
        <span>Confirm purchasing license?</span>
      </ModalBox>
      <Spinner loading={loading} loadingError={loadingError} loadingText="Loading my package">
        <PaginatedTable
          url={urlRef.current}
          dataName="packages"
          type="card"
          cardView={cardViewRef}
          fields={{} as any}
          primaryKey="code"
          searchKey="name"
          forCurrentUser={false}
          noControl
          hidePaginator
        />
      </Spinner>
    </>
  )
}

export default UpgradesTab
