/* eslint-disable @typescript-eslint/no-explicit-any */
//import React from 'react';
import passwordValidator from 'password-validator'

const passwordStrong = ({ password }: any) => {
  const schema = new passwordValidator()

  // Add your password strength rules
  schema
    .is()
    .min(8) // Minimum length 8
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits() // Must have digits
    .has()
    .not()
    .spaces() // Should not have spaces

  return schema.validate(password)
}

export default passwordStrong
