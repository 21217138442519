import React, { useContext, useMemo } from 'react'
import IOdd from '../../../../interface/IOdd'
import { OddContextAction, OddsContext, getOddIndex } from '../../../context/oddsContext'

export default function OddButton({ odd }: { odd: IOdd }) {
  const { odds, oddContextDispatcher } = useContext(OddsContext)
  const selected = useMemo(() => getOddIndex(odds, odd) > -1, [odds])

  return (
    <>
      <button
        onClick={() =>
          !odd.value
            ? ''
            : oddContextDispatcher({
                odd,
                action: selected ? OddContextAction.POP : OddContextAction.PUSH,
              })
        }
        className={`btn btn-danger ${selected ? 'active' : ''}`}
        style={{ padding: '2px 5px', minWidth: '45px' }}
        disabled={!odd.value}
      >
        {odd.value || '-'}
      </button>
    </>
  )
}
