/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import PasswordStrengthMeter from './PasswordStrengthMeter'
import passwordStrong from './PasswordStrong'

const PasswordInput = (props: any) => {
  const [pass, setPass] = useState('')
  const [showPass, setShowPass] = useState(false)

  const handlePasswordChange = (event: { target: { value: any } }) => {
    const password = event.target.value
    const isPasswordStrong = passwordStrong({ password })
    if (Array.isArray(isPasswordStrong)) {
      props.setIsPasswordStrong && props.setIsPasswordStrong(false)
    } else {
      props.setIsPasswordStrong && props.setIsPasswordStrong(isPasswordStrong)
    }
    props.setPassword && props.setPassword(password)
    setPass(password)
  }

  return (
    <>
      <div className="input-group input-group-merge mb-1">
        <input
          type={props.showPassword || showPass ? 'text' : 'password'}
          className={props.className || 'form-control'}
          placeholder={props.placeholder || '*********'}
          aria-describedby="password"
          value={pass}
          onChange={handlePasswordChange}
          required={props.required}
        />
        <div className="input-group-addon cursor-pointer" onClick={() => setShowPass(!showPass)}>
          <span className="input-group-text ">
            <i className={`bx bx-${props.showPassword || showPass ? 'show' : 'hide'}`}></i>
          </span>
        </div>
      </div>
      {props.noMeter ? null : <PasswordStrengthMeter />}
    </>
  )
}

export default PasswordInput
