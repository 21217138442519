/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, Navigate, useLocation } from 'react-router-dom'
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import fetcher from '../scripts/SharedFetcher'
import { API_VERSION, BASE, GOAUTH, USER_BASE } from '../scripts/config/RestEndpoints'
import SharedConfig from '../scripts/SharedConfig'
import PasswordInput from '../components/general/PasswordInput'
import IResStruct from '../interface/IResStruct'
import Loader from '../components/general/Loader'

function SignUp() {
  const location = useLocation()
  const [ref, setRef] = useState(new URLSearchParams(location.search)?.get('ref'))

  const [email, setEmail] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [isPasswordStrong, setIsPasswordStrong] = useState('')

  const [created, setCreated] = useState(false)
  const [loading, setLoading] = useState(false)

  function oauth() {
    setLoading(true)
    const url = BASE + '/' + API_VERSION + GOAUTH
    window.location.href = url
  }

  async function signUp(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!password || password !== passwordConfirm) {
      toast.error('Password Mismatch')
      return
    }
    setLoading(true)
    const option = {
      url: USER_BASE,
      data: {
        email,
        firstname,
        lastname,
        password,
        refID: ref,
      },
    }
    let returns
    try {
      returns = await fetcher.fetch<IResStruct>(option)
    } catch (error) {
      toast.error((error as any).message)
      return
    }
    if (!returns?.data?.status) {
      toast.error(returns?.data?.message || 'Error')
    } else {
      toast.success(returns.data.message)
      toast.success('Please login to finish up ....')
      setTimeout(() => setCreated(true), 1000 * 5)
      SharedConfig.destroyAll()
    }
    setLoading(false)
  }

  return created ? (
    <Navigate to="/login" />
  ) : (
    <>
      <h3 className="text-center">
        Create your ${SharedConfig.getSessionData('SITE_TITLE')} account in less than a minute.
      </h3>
      <br />
      <Row className="padding-1x">
        <Col className="padding-3x">
          <div className="panel panel-default no-curve">
            <div className="panel-body">
              <form id="sign-up-form" onSubmit={(e) => signUp(e)}>
                <div className="row">
                  <div className="col-xs-12">
                    <div className="form-group">
                      <label htmlFor="email" className="form-label">
                        Email <span className="required">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className="form-group">
                      <label htmlFor="firstname" className="form-label">
                        First Name<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="First name"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className="form-group">
                      <label htmlFor="lastname" className="form-label">
                        Last Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className="form-group">
                      <label htmlFor="ref" className="form-label">
                        Referral Id
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Your Referral Id (Optional)"
                        value={typeof ref !== 'number' ? parseInt(`${ref}`) : ref}
                        onChange={(e) => setRef(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className="form-group">
                      <label htmlFor="password">
                        Password <span className="required">*</span>
                      </label>
                      <PasswordInput setPassword={setPassword} setIsPasswordStrong={setIsPasswordStrong} required />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <div className="form-group">
                      <label htmlFor="password">
                        Confirm Password <span className="required">*</span>
                      </label>
                      <PasswordInput
                        setPassword={setPasswordConfirm}
                        placeholder={'Comfirm Password'}
                        noMeter
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12">
                    <button
                      className="btn btn-info no-curve pull-right"
                      type="submit"
                      disabled={!isPasswordStrong || loading}
                      title={!isPasswordStrong ? 'Use strong password to continue' : ''}
                    >
                      Sign Up {loading ? <Loader /> : ''}
                    </button>
                  </div>
                </div>
              </form>
              <div className="social-auth-links text-center">
                <p>- OR -</p>
                <a className="btn btn-block btn-social btn-google btn-flat" href="#" onClick={oauth}>
                  <i className="fa fa-google-plus"></i> Sign up using Google+
                </a>
              </div>
              <Link to="/login" className="text-center">
                I already have an account
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default SignUp
