import React from 'react'
//import passwordValidator from "password-validator";

const PasswordStrengthMeter = () => {
  return (
    <small>
      <ul>
        <li>Minimum length 8</li>
        <li>Must have uppercase letters</li>
        <li>Must have lowercase letters</li>
        <li>Must have digits</li>
        <li>Should not have spaces</li>
      </ul>
    </small>
  )
}

export default PasswordStrengthMeter
