import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function DockerButton({ active, name, onClick }: any) {
  return (
    <li title={`${name}`}>
      <a
        href="javascript:void"
        className={`overflow`}
        style={{
          width: '20%',
          float: 'left',
          padding: '5px 5px',
          color: active ? '#FFD700' : '#ffffff',
        }}
        {...{ onClick }}
      >
        {name}
      </a>
    </li>
  )
}

export default DockerButton
