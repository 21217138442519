/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-jsdoc */

import React, { createContext, useReducer } from 'react'
import IOdd from '../../interface/IOdd'

export enum OddContextAction {
  PUSH,
  POP,
}

interface OddContextDispatch {
  action: OddContextAction
  odd: IOdd
}

export const getOddIndex = (odds: IOdd[], odd: IOdd) => {
  const index = odds.findIndex((o) => o._id == odd._id)
  return index
}

function reducer(previousOdds: IOdd[], oddContextDispatch: OddContextDispatch) {
  switch (oddContextDispatch.action) {
    case OddContextAction.PUSH:
      previousOdds.push(oddContextDispatch.odd)
      break

    case OddContextAction.POP:
      // eslint-disable-next-line no-case-declarations
      const index = getOddIndex(previousOdds, oddContextDispatch.odd)
      if (index) {
        delete previousOdds[index]
      }
      break
  }
  return previousOdds || []
}

interface IReducerContext {
  odds: IOdd[]
  // eslint-disable-next-line no-empty-pattern
  oddContextDispatcher: ({ odds: [] }: OddContextDispatch) => any
}

const OddsContext = createContext<IReducerContext>({ odds: [] } as any)

const OddsProvider = ({ children }: any) => {
  const [odds, oddContextDispatcher] = useReducer(reducer, [])

  return <OddsContext.Provider value={{ odds, oddContextDispatcher }}>{children}</OddsContext.Provider>
}

export { OddsContext, OddsProvider }
