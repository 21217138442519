import React from 'react'
import ReactDOM from 'react-dom/client'
import './assets/vendor/fonts/boxicons.css'
import './assets/css/misc.css'

import 'react-toastify/dist/ReactToastify.min.css'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NotFound from './pages/NotFound'
import Routing from './scripts/Routing'
import Dashboard, { OpenType } from './pages/Dashboard'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const routing = new Routing()
const inSecuredRoutes = routing.getInSecuredRoutes()
const securedRoutes = routing.getSecuredRoutes()
root.render(
  <BrowserRouter>
    <Routes>
      {inSecuredRoutes}
      {securedRoutes}
      <Route path="/login" Component={(props) => <Dashboard open={OpenType.LOGIN} {...props} />} />
      <Route path="/signup" Component={(props) => <Dashboard open={OpenType.SIGN_UP} {...props} />} />
      <Route path="/forget-password" Component={(props) => <Dashboard open={OpenType.FORGOT_PASSWORD} {...props} />} />
      <Route path="*" Component={(props) => <NotFound {...props} />} />
    </Routes>
  </BrowserRouter>,
)
