import React from 'react'
import IMatch from '../../../../interface/IMatch'

export default function NameScore({ match }: { match: IMatch }) {
  return (
    <>
      <div className="col-lg-3 col-md-3 col-sm-3 col-xs-7 text-left text-bold" title={`${match.name}`}>
        <small className="text-muted overflow" style={{}}>
          {match.leagueId.name}
          <div className="overflow">{match.isLive ? 'live' : ''}</div>
          <div className="overflow">{match.matchHasEnded ? 'ended' : ''}</div>
        </small>
        <div className="col-xs-10" style={{ paddingLeft: '0px' }}>
          <div className="overflow">{match.homeTeam}</div>
          <div className="overflow">{match.awayTeam}</div>
        </div>

        <div className="col-xs-2" style={{ paddingLeft: '0px', color: 'azure' }}>
          <div className="">{match.homeScore}</div>
          <div className="">{match.awayScore}</div>
        </div>
      </div>
    </>
  )
}
